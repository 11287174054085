<template>
  <section class="section light-bg">
    <div class="container">
      <div class="reviews">
        <h2 class="title">Отзывы клиентов TERPO software</h2>
        <div class="reviews__list">
          <carousel :items-to-show="3.5" :breakpoints="breakpoints">
            <slide v-for="(item, index) in reviews" :key="`review-${index}`">
              <review-card
                :data="item"
                preview
                @openInfo="
                  (data: IData) => {
                    openModal(data);
                  }
                "
              />
            </slide>
            <template #addons>
              <div class="carousel-nav">
                <Navigation />
              </div>
            </template>
          </carousel>
        </div>
      </div>
    </div>
  </section>
  <modal v-if="modalOpen && state.activeData" @close="closeModal">
    <div class="review-wrapper">
      <review-card :data="state.activeData" />
    </div>
  </modal>
</template>

<script setup lang="ts">
import reviewCard from '~/components/blocks/review.vue';
import modal from '~/components/sections/modal.vue';
import type { IData } from '~/components/blocks/types';

interface IState {
  activeData: IData | null;
}

const state: IState = {
  activeData: null,
};

const modalOpen = ref<boolean>(false);
const openModal = (item: IData): void => {
  modalOpen.value = true;
  document.body.style.overflow = 'hidden';
  state.activeData = item;
};

const closeModal = (): void => {
  modalOpen.value = false;
  document.body.style.overflow = 'auto';
  state.activeData = null;
};

const reviews: IData[] = [
  {
    title: 'Магазин «ВСЕ ЗАРЯДКИ»',
    textPreview:
      'В TERPO разработали для нас систему управления продажами за 2,5 месяца и, что немаловажно, за максимально разумную сумму. Рекомендуем!',
    text: 'В TERPO разработали для нас систему управления продажами за 2,5 месяца и, что немаловажно, за максимально разумную сумму. Рекомендуем! Продукт и подход к работе с заказчиками действительно впечатляют.',
    photo: '/img/reviews/customer2.jpg',
  },
  {
    title: 'Торговая компания «Vespa»',
    textPreview:
      'Сколько мы пробовали готовых решений - не счесть. В итоге поняли, что проще найти подрядчика и разработать систему под нас. ',
    text: 'Сколько мы пробовали готовых решений - не счесть. В итоге поняли, что проще найти подрядчика и разработать систему под нас. Довольны как системой, так и процессом работы с TERPO. Наконец-то CRM на 100% подходит под бизнес-задачи нашего отдела продаж. На очереди автоматизация HR. Однозначно рекомендуем.',
    photo: '/img/reviews/customer3.jpg',
  },
  {
    title: 'Магазин «Легкие решения»',
    textPreview: 'Благодарим TERPO за разработку и внедрение CRM. Для нас это настоящий прорыв в организации работы нашего предприятия.',
    text: 'Благодарим TERPO за разработку и внедрение CRM. Для нас это настоящий прорыв в организации работы нашего предприятия. Мы теперь можем легко собирать и анализировать информацию о покупателях, что позволяет делать им персональные предложения и повышать уровень обслуживания.',
    photo: '/img/reviews/customer4.jpg',
  },
];

const breakpoints = {
  300: {
    itemsToShow: 1,
    snapAlign: 'center',
  },
  500: {
    itemsToShow: 1,
    snapAlign: 'start',
  },
  768: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1300: {
    itemsToShow: 3,
    snapAlign: 'start',
  },
};
</script>

<style scoped lang="scss">
.reviews {
  color: var(--text-color);
  .title {
    margin-bottom: 36px;
  }

  &__list {
    display: flex;
    position: relative;
    margin: 0 -30px;
  }

  &__nav-btn {
    width: 65px;
    height: 64px;
    border: none;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-nav {
    right: 30px;
  }

  .carousel__slide {
    padding: 0 30px;
  }
}
</style>
