<template>
  <gridData :data="advantages" title="Почему мы?" />
</template>

<script setup lang="ts">
import Code from '~/components/icons/index/adv-code.vue';
import Cycle from '~/components/icons/index/cycle.vue';
import Pen from '~/components/icons/index/pen.vue';
import Calc from '~/components/icons/index/calc.vue';
import Settings from '~/components/icons/index/settings.vue';
import gridData from '~/components/blocks/grid-data.vue';

const advantages = [
  {
    text: 'Российский разработчик с глубоким пониманием локального рынка',
    icon: Code,
  },
  {
    text: 'Полный цикл разработки: от идеи до технической поддержки',
    icon: Cycle,
  },
  {
    text: 'Интеграция ПО в существующие бизнес-процессы',
    icon: Pen,
  },
  {
    text: 'Гибкость и прозрачность в ценообразовании',
    icon: Calc,
  },
  {
    text: 'Модернизация и доработка ваших текущих IT-решений',
    icon: Settings,
  },
];
</script>
